<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>订单管理</el-breadcrumb-item>
			  <el-breadcrumb-item>订单列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<el-form :inline="true" :model="form" class="demo-form-inline">
					<el-form-item label="账号昵称" style="width: 300px; margin-bottom: 10px;">
						<el-input v-model="form.username" placeholder="请输入会员账号昵称" clearable />
					</el-form-item>
					<el-form-item label="订单编码" style="width: 300px; margin-bottom: 10px;">
						<el-input v-model="form.order_sn" placeholder="请输入订单编码" clearable />
					</el-form-item>
					<el-form-item label="订单金额" style="width: 300px; margin-bottom: 10px;">
						<el-input v-model="form.amount" placeholder="请输入订单金额" clearable />
					</el-form-item>
				    <el-form-item label="起始时间" style="width: 300px; margin-bottom: 10px;">
						<el-date-picker v-model="form.start_time" type="date" value-format="yyyy-MM-dd" placeholder="选择起始时间" clearable />
				    </el-form-item>
					<el-form-item label="至"  style="width: 300px; margin-bottom: 10px;">
						<el-date-picker v-model="form.ended_time" type="date" value-format="yyyy-MM-dd" placeholder="选择结束时间" clearable />
					</el-form-item>
					
					<el-form-item label="中奖状态" style="width: 300px;">
						<el-select  v-model="form.status" placeholder="中奖状态" clearable >
							<el-option label="全部" value=""  style="width: 300px;"/>
							<el-option label="未开奖" value="0"  style="width: 300px;"/>
							<el-option label="中奖" value="1"  style="width: 300px;"/>
							<el-option label="未中将" value="2"  style="width: 300px;"/>
							<el-option label="已兑奖" value="3"  style="width: 300px;"/>
							<el-option label="已退票" value="4"  style="width: 300px;"/>
						</el-select>
					</el-form-item>
					<el-form-item label="打印时间" style="width: 300px;">
						<el-input v-model="form.print_time" placeholder="请输入打印时间" clearable />
					</el-form-item>
					<el-form-item label="票面编码"  style="width: 300px;">
						<el-input v-model="form.paper_no" placeholder="请输入票面编码" clearable />
					</el-form-item>
					<el-form-item label="玩法类型"  style="width: 300px;">
						<el-select v-model="form.game" placeholder="玩法类型" clearable>
							<el-option label="全部" value="" />
							<el-option label="竞彩足球" value="竞彩足球" />
							<el-option label="竞彩篮球" value="竞彩篮球" />
							<el-option label="北京单场" value="北京单场" />
							<el-option label="排列三" value="排列三" />
						</el-select>
					</el-form-item>
				    <el-form-item>
						<el-button type="primary" @click="onSubmit">搜索</el-button>
				    </el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" border  style="width: 100%" v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)">
				<el-table-column type="selection" width="55" />
			    <el-table-column prop="orid" label="订单编码" width="90" align="center"/>
				<el-table-column prop="username" label="会员账号/昵称" width="120" align="center">
					<template #default="scope">
						{{scope.row.username}}<br>{{scope.row.nickname}}
					</template>
				</el-table-column>
				<el-table-column prop="game" label="游戏" width="140"  align="center"/>
			    <el-table-column prop="types" label="玩法类型" width="180"  align="center"/>
			    
				<el-table-column prop="amount" label="总金额" width="130" align="center">
					<template #default="scope">
						￥{{scope.row.amount}}
					</template>
				</el-table-column>
				<el-table-column prop="status" label="中奖状态"  width="130" align="center">
					<template #default="scope">
						<span v-if="scope.row.status==0">未开奖</span>
						<span v-if="scope.row.status==1" style="color:green;">中奖</span>
						<span v-if="scope.row.status==2" style="color:red;">未中奖</span>
						<span v-if="scope.row.status==3" style="color:green;">已兑奖</span>
						<span v-if="scope.row.status==4" style="color:red;">已退票</span>
					</template>
				</el-table-column>
				<el-table-column prop="award" label="中奖金额" width="120" align="center">
					<template #default="scope">
						<span v-if="scope.row.status==0">￥{{scope.row.award}}</span>
						<span v-if="scope.row.status==1" style="color:green;">￥{{scope.row.award}}</span>
						<span v-if="scope.row.status==2" style="color:red;">￥{{scope.row.award}}</span>
						<span v-if="scope.row.status==3" style="color:green;">￥{{scope.row.award}}</span>
						<span v-if="scope.row.status==4" style="color:red;">￥{{scope.row.award}}</span>
						
					</template>
				</el-table-column>
				<el-table-column prop="customer" label="客户名称" width="120" align="center"/>
				<el-table-column prop="add_time" label="下单时间" width="200" align="center"></el-table-column>
				<el-table-column prop="print_time" label="打印时间"  width="200" align="center"></el-table-column>
				<el-table-column prop="" label="操作" align="center">
					<template #default="scope">
					    <el-button size="small" type="primary" @click="viewOrder(scope)">查看</el-button>
	<!-- 				    <el-button size="small" type="danger" @click="deletes(scope)">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<div style="margin-top: 20px; display: flex; flex-direction: row; justify-content: flex-end;">
			<el-pagination
			      @size-change="handleSizeChange"
			      @current-change="handleCurrentChange"
			      :current-page="currentPage"
			      :page-sizes="[10, 20, 50, 100]"
			      :page-size="limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="total">
			    </el-pagination>
			</div>
		</div>
		
		
		<el-drawer v-if="viewOrderInfo" :visible.sync="PL3Drawer" title="订单详情" size="900" direction="rtl" :before-close="handleClose">
			<el-card style="margin-bottom:10px; font-size: 14px;">
				<div style="display: flex; flex-direction: row; justify-content: left;">
					<div style="width: 280px;">订单号：<b>{{viewOrderInfo.orid}}</b></div>
				</div>
			</el-card>
			<el-card style="margin-bottom:10px; font-size: 14px;">
				<div style="display: flex; flex-direction: row; justify-content: left;">
					<div style="width: 280px;">订单状态：
						<b v-if="viewOrderInfo.status==0">已出票</b>
						<b v-if="viewOrderInfo.status==1"><span style="color:green;">中奖</span></b>
						<b v-if="viewOrderInfo.status==2"><span style="color:red;">未中奖</span></b>
						<b v-if="viewOrderInfo.status==3"><span style="color:green;">已兑奖</span></b>
						<b v-if="viewOrderInfo.status==4"><span style="color:red;">已退票</span></b>
					</div>
					<div style="width: 280px;">下单时间：<b>{{viewOrderInfo.add_time}}</b></div>
					<div style="width: 280px;">打印时间：<b>{{viewOrderInfo.print_time}}</b></div>
				</div>
			</el-card>
			<el-card style="margin-bottom:10px; font-size: 14px;">
				<div style="display: flex; flex-direction: row; justify-content: left;">
					<div style="width: 280px;">游戏：<b>{{viewOrderInfo.game}}</b></div>
					<div style="width: 280px;">期号：<b>{{viewOrderInfo.dateNo}}</b></div>
					<div style="width: 280px;">投注类型：<b>{{viewOrderInfo.types}}票</b></div>
				</div>
			</el-card>
			<el-card style="margin-bottom:10px; font-size: 14px;">
				<div style="display: flex; flex-direction: row; justify-content: left;">
					<div style="width: 280px;">注数：<b>{{viewOrderInfo.amount / 2/ viewOrderInfo.multiple}}</b></div>
					<div style="width: 280px;">投注倍数：<b>{{viewOrderInfo.multiple}}</b></div>
					<div style="width: 280px;">投注金额：<b>￥{{viewOrderInfo.amount}}</b></div>
				</div>
			</el-card>
			<el-card style="margin-bottom:10px;" v-if="viewOrderInfo.types=='直选'">
				<table border="1" width="100%" style="border-collapse: collapse; border:solid 1px #dcdcdc; color: #666666; font-size: 12px;">
					<tr style="width: 100%;" v-for="(item,index) in viewOrderInfo.other_desc.list">
						<td style="width: 50%; padding:10px;">第{{index+1}}注</td>
						<td style="width: 50%; padding:10px;">[ {{item.num1}}, {{item.num2}}, {{item.num3}} ]</td>
					</tr>
				</table>
			</el-card>
			<el-card style="margin-bottom:10px;" v-if="viewOrderInfo.types=='直选复式'">
				<table border="1" width="100%" style="border-collapse: collapse; border:solid 1px #dcdcdc; color: #666666; font-size: 12px;">
					<tr style="width: 100%;">
						<td style="width: 50%; padding:10px;">第1位</td>
						<td style="width: 50%; padding:10px;"><span v-for="(i,t) in viewOrderInfo.other_desc.list.list1">{{i}}&nbsp;&nbsp;</span></td>
					</tr>
					<tr style="width: 100%;">
						<td style="width: 50%; padding:10px;">第2位</td>
						<td style="width: 50%; padding:10px;"><span v-for="(i,t) in viewOrderInfo.other_desc.list.list2">{{i}}&nbsp;&nbsp;</span></td>
					</tr>
					<tr style="width: 100%;">
						<td style="width: 50%; padding:10px;">第3位</td>
						<td style="width: 50%; padding:10px;"><span v-for="(i,t) in viewOrderInfo.other_desc.list.list3">{{i}}&nbsp;&nbsp;</span></td>
					</tr>
				</table>
			</el-card>
			<el-card style="margin-bottom:10px;" v-if="viewOrderInfo.types=='直选组合复式'">
				<table border="1" width="100%" style="border-collapse: collapse; border:solid 1px #dcdcdc; color: #666666; font-size: 12px;">
					<tr style="width: 100%;">
						<td style="width: 50%; padding:10px;">直选组合复式</td>
						<td style="width: 50%; padding:10px;"><span v-for="(i,t) in viewOrderInfo.other_desc.list">{{i}}&nbsp;&nbsp;</span> ({{viewOrderInfo.other_desc.zhfs}})</td>
					</tr>
				</table>
			</el-card>
			<el-card style="margin-bottom:10px;" v-if="viewOrderInfo.types=='直选组合胆拖' || viewOrderInfo.types=='组选3胆拖' || viewOrderInfo.types=='组选6胆拖'">
				<table border="1" width="100%" style="border-collapse: collapse; border:solid 1px #dcdcdc; color: #666666; font-size: 12px;">
					<tr style="width: 100%;">
						<td style="width: 50%; padding:10px;">胆码</td>
						<td style="width: 50%; padding:10px;"><span v-for="(i,t) in viewOrderInfo.other_desc.dan">{{i}}&nbsp;&nbsp;</span></td>
					</tr>
					<tr style="width: 100%;">
						<td style="width: 50%; padding:10px;">拖码</td>
						<td style="width: 50%; padding:10px;"><span v-for="(i,t) in viewOrderInfo.other_desc.tuo">{{i}}&nbsp;&nbsp;</span></td>
					</tr>
				</table>
			</el-card>
			<el-card style="margin-bottom:10px;" v-if="viewOrderInfo.types=='组选'">
				<table border="1" width="100%" style="border-collapse: collapse; border:solid 1px #dcdcdc; color: #666666; font-size: 12px;">
					<tr style="width: 100%;" v-for="(item,index) in viewOrderInfo.other_desc.list">
						<td style="width: 50%; padding:10px;">第{{index+1}}注</td>
						<td style="width: 50%; padding:10px;">[ {{item.num1}}, {{item.num2}}, {{item.num3}} ]</td>
					</tr>
				</table>
			</el-card>
			<el-card style="margin-bottom:10px;" v-if="viewOrderInfo.types=='组选3复式' || viewOrderInfo.types=='组选6复式' || viewOrderInfo.types=='组选和值'
				|| viewOrderInfo.types=='组选3跨度' || viewOrderInfo.types=='组选6跨度' || viewOrderInfo.types=='直选跨度' || viewOrderInfo.types=='直选和值'
				 || viewOrderInfo.types=='两码全包' || viewOrderInfo.types=='直选组合二同' || viewOrderInfo.types=='直选组合二不同' || viewOrderInfo.types=='直选组合三不同'">
				<table border="1" width="100%" style="border-collapse: collapse; border:solid 1px #dcdcdc; color: #666666; font-size: 12px;">
					<tr style="width: 100%;">
						<td style="width: 50%; padding:10px;">{{viewOrderInfo.types}}</td>
						<td style="width: 50%; padding:10px;"><span v-for="(i,t) in viewOrderInfo.other_desc.list">{{i}}&nbsp;&nbsp;</span></td>
					</tr>
				</table>
			</el-card>
			<el-card style="margin-bottom:10px;" v-if="viewOrderInfo.types=='直组混选'">
				<table border="1" width="100%" style="border-collapse: collapse; border:solid 1px #dcdcdc; color: #666666; font-size: 12px;">
					<tr style="width: 100%;" v-for="(item,index) in viewOrderInfo.other_desc.list">
						<td style="width: 50%; padding:10px;">第{{index+1}}注</td>
						<td style="width: 50%; padding:10px;">[ {{item.num1}}, {{item.num2}}, {{item.num3}} ] {{item.type}} ({{item.mul}}倍)</td>
					</tr>
				</table>
			</el-card>
		</el-drawer>
			
		<el-drawer v-if="viewOrderInfo" :visible.sync="FootballDrawer" title="订单详情" size="1200" direction="rtl" :before-close="handleClose">
			<el-card style="margin-bottom:10px; font-size: 14px;">
				<div style="display: flex; flex-direction: row; justify-content: left;">
					<div style="width: 280px;">订单号：<b>{{viewOrderInfo.orid}}</b></div>
					<div style="width: 280px;">订单状态：
						<b v-if="viewOrderInfo.status==0">已出票</b>
						<b v-if="viewOrderInfo.status==1"><span style="color:green;">中奖</span></b>
						<b v-if="viewOrderInfo.status==2"><span style="color:red;">未中奖</span></b>
						<b v-if="viewOrderInfo.status==3"><span style="color:green;">已兑奖</span></b>
						<b v-if="viewOrderInfo.status==4"><span style="color:red;">已退票</span></b>
					</div>
					<div style="width: 280px;">下单时间：<b>{{viewOrderInfo.add_time}}</b></div>
					<div style="width: 280px;">打印时间：<b>{{viewOrderInfo.print_time}}</b></div>
				</div>
			</el-card>
			<el-card style="margin-bottom:10px; font-size: 14px;">
				<div style="display: flex; flex-direction: row; justify-content: left;">
					<div style="width: 280px;">游戏：<b>{{viewOrderInfo.game}}</b></div>
					<div style="width: 280px;">期号：<b>{{viewOrderInfo.dateNo}}</b></div>
					<div style="width: 280px;">投注类型：<b>{{viewOrderInfo.types}}</b></div>
					<div style="width: 280px;">过关方式：<b>{{viewOrderInfo.other_desc.sub_types}}</b></div>
				</div>
			</el-card>
			<el-card style="margin-bottom:10px; font-size: 14px;">
				<div style="display: flex; flex-direction: row; justify-content: left;">
					<div style="width: 540px; padding-right:20px;">注数：<b>{{viewOrderInfo.other_desc.count_ster}}</b></div>
					<div style="width: 280px;">投注倍数：<b>{{viewOrderInfo.multiple}}</b></div>
					<div style="width: 280px;">投注金额：<b>￥{{viewOrderInfo.amount}}</b></div>
				</div>
			</el-card>
			<el-card style="margin-bottom:10px;">
				<table border="1" width="100%" style="border-collapse: collapse; border:solid 1px #dcdcdc; color: #666666; font-size: 12px;">
					<tr style="width: 100%;" v-for="(item,index) in viewOrderInfo.sub_list">
						<td style="padding:10px;">{{item.weekNo}}</td>
						<td style="padding:10px;" v-if="viewOrderInfo.game=='竞彩足球'">{{item.home_team}} VS {{item.away_team}}</td>
						<td style="padding:10px;" v-if="viewOrderInfo.game=='竞彩篮球'">{{item.away_team}} VS {{item.home_team}}</td>
						<td style="padding:10px;">
							<span v-if="item.pool=='had'">胜平负</span>
							<span v-if="item.pool=='hhad'">让球胜平负</span>
							<span v-if="item.pool=='crs'">比分</span>
							<span v-if="item.pool=='ttg'">总进球</span>
							<span v-if="item.pool=='hafu'">半全场胜平负</span>
							
							
							<span v-if="item.pool=='mnl'">胜 负</span>
							<span v-if="item.pool=='hdc'">让分胜负</span>
							<span v-if="item.pool=='wnm'">胜分差</span>
							<span v-if="item.pool=='hilo'">大小分</span>
						</td>
						<td style="padding:10px;">
							{{item.bet_json}}
						</td>
					</tr>
				</table>
			</el-card>
		</el-drawer>
		
		<el-drawer v-if="viewOrderInfo" :visible.sync="PekingDrawer" title="订单详情" size="1200" direction="rtl" :before-close="handleClose">
			<el-card style="margin-bottom:10px; font-size: 14px;">
				<div style="display: flex; flex-direction: row; justify-content: left;">
					<div style="width: 280px;">订单号：<b>{{viewOrderInfo.orid}}</b></div>
					<div style="width: 280px;">订单状态：
						<b v-if="viewOrderInfo.status==0">已出票</b>
						<b v-if="viewOrderInfo.status==1"><span style="color:green;">中奖</span></b>
						<b v-if="viewOrderInfo.status==2"><span style="color:red;">未中奖</span></b>
						<b v-if="viewOrderInfo.status==3">已兑奖</b>
						<b v-if="viewOrderInfo.status==4">已退票</b>
					</div>
					<div style="width: 280px;">下单时间：<b>{{viewOrderInfo.add_time}}</b></div>
					<div style="width: 280px;">打印时间：<b>{{viewOrderInfo.print_time}}</b></div>
				</div>
			</el-card>
			<el-card style="margin-bottom:10px; font-size: 14px;">
				<div style="display: flex; flex-direction: row; justify-content: left;">
					<div style="width: 280px;">游戏：<b>{{viewOrderInfo.game}}</b></div>
					<div style="width: 280px;">期号：<b>{{viewOrderInfo.dateNo}}</b></div>
					<div style="width: 280px;">投注类型：<b>北京单场{{viewOrderInfo.types}}</b></div>
					<div style="width: 280px;">过关类型：<b>{{viewOrderInfo.other_desc.pass_type}}</b></div>
				</div>
			</el-card>
			<el-card style="margin-bottom:10px; font-size: 14px;">
				<div style="display: flex; flex-direction: row; justify-content: left;">
					<div style="width: 280px;">注数：<b>{{viewOrderInfo.amount/2/viewOrderInfo.multiple}}</b></div>
					<div style="width: 280px;">投注倍数：<b>{{viewOrderInfo.multiple}}</b></div>
					<div style="width: 280px;">投注金额：<b>￥{{viewOrderInfo.amount}}</b></div>
					<div style="width: 280px;">过关方式：<b>{{viewOrderInfo.other_desc.pass_param}}</b></div>
				</div>
			</el-card>
			<el-card style="margin-bottom:10px;">
				<table border="1" width="100%" style="border-collapse: collapse; border:solid 1px #dcdcdc; color: #666666; font-size: 12px;">
					<tr style="width: 100%;">
						<td style="padding:10px;">编号</td><td style="padding:10px;">投注项</td><td style="padding:10px;">投注类型</td><td style="padding:10px;">投注赔率</td>
					</tr>
					<tr style="width: 100%;" v-for="(item,index) in viewOrderInfo.sub_list">
						<td style="padding:10px;">{{item.weekNo}}</td>
						<td style="padding:10px;">{{item.home_team}} VS {{item.away_team}}</td>
						<td style="padding:10px;">{{viewOrderInfo.types}}</td>
						<td style="padding:10px;">
							{{item.bet_json}}
						</td>
					</tr>
				</table>
			</el-card>
		</el-drawer>
		
		
		
	</div>
</template>

<script>
	
	export default {
		components:{
		},
		name:'List',
		data(){
			return {
				PL3Drawer:false,
				FootballDrawer:false,
				PekingDrawer:false,
				
				drawer:false,
				tableData:[],
				form:{
					customer:'',
					order_sn:'',
					amount:'',
					start_time:'',
					ended_time:'',
					status:'',
					print_time:'',
					paper_no:'',
					game:''
				},
				limit:10,
				currentPage:1,
				total:0,
				loading:false,
				viewOrderInfo:null
			}
		},
		created() {
			this.getList();
		},
		methods:{
			handleClose(){
				this.PekingDrawer = false;
				this.PL3Drawer = false;
				this.FootballDrawer = false;
				this.drawer = false;
				// this.viewOrderInfo = {};
			},
			viewOrder(scope){
				let _this = this;
				_this.$api.post("/Order/getOrderDetail", {orid:scope.row.orid}).then(res=>{
					if(res.code == 1){
						_this.viewOrderInfo = res.data;
						if(res.data.game=='竞彩足球' || res.data.game=='竞彩篮球'){
							_this.FootballDrawer = true;
						}else if(res.data.game=='排列三'){
							_this.PL3Drawer = true;
						}else if(res.data.game == '北京单场'){
							_this.PekingDrawer = true;
						}
					}
					
				}).catch(error=>{
					_this.loading = false;
				});
			},
			handleSizeChange(e){
				this.limit = e;
				this.getList();
			},
			handleCurrentChange(e){
				this.currentPage = e;
				this.getList();
			},
			refresh(){
				this.currentPage = 1;
				this.getList();
				this.tableKey +=1;
			},
			getList(){
				let _this = this;
				_this.loading = true;
				_this.$api.post("/AgentOrder/getList", {limit:_this.limit, page:_this.currentPage, ...this.form}).then(res=>{
					_this.loading = false;
					if(res.code == 1){
						_this.tableData = res.data.list;
						_this.total = res.data.total;
					}
				}).catch(error=>{
					_this.loading = false;
				});
			},
			handleClick(e){
			},
			onSubmit(){
				this.getList();
			}
		}
	}
</script>