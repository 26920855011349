<style scoped>
	
	
	.line-box {
		display: flex; flex-direction: row; height: 60px; align-items: center;
		margin-left: 0px;
	}
	.line-name {
		width: 80px; text-align: right; padding-right: 10px;
	}
	.line-tag-box {
		display: flex; flex-direction: row;  align-items: center;
		margin-left: 0px;
	}
	.line-tag {
		width: 700px;  background-color: #f2dede; color: #b94a48; line-height: 20px; padding: 10px; border-radius: 3px;
	}
	
	.upload-box {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 60px;
	    height: 60px;
	    line-height: 60px;
	    text-align: center;
	  }
	  .avatar {
	    width: 60px;
	    height: 60px;
	    display: block;
	  }
</style>

<template>
	<div class="content">
		<div style="background-color: white; border-radius: 10px;">
		<div class="line-box">
			<div class="line-name">会员编号：</div>
			<el-input type="number" style="width: 720px;" v-model="userinfo.uid" disabled ></el-input>
		</div>
		
		<div class="line-box">
			<div class="line-name">会员账号：</div>
			<el-input style="width: 720px;" v-model="userinfo.username" disabled placeholder="会员账号" ></el-input>
		</div>
		
		<div class="line-box">
			<div class="line-name">到期时间：</div>
			<el-input type="text" style="width: 720px;" disabled v-model="userinfo.end_time" placeholder="到期时间"></el-input>
		</div>
		
		<div class="line-box">
			<div class="line-name">加减时长：</div>
			<el-input type="number" style="width: 720px;" v-model="change" placeholder="加减时长:如7或-7(单位天)"></el-input>
		</div>
		
		
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">说明：增加多少时长填多少，扣减多少时长填负多少。如加7天（填7），减7天（填-7)</div>
		</div>
		
		<div style="width: 100%; margin-top: 20px; ">
			<el-button style="width: 760px;" type="danger" @click="submit">确认充值</el-button>
		</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:['userinfo'],
		data(){
			return {
				change:'',
				reason:'',
			}
		},
		created(){
		},
		methods: {
			submit(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '处理中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/User/Admin2UserPayApi", {uid:_this.userinfo.uid, change:_this.change}).then(res=>{
					loading.close();
					if(res.code == 1)
					{
						_this.reason = '';
						_this.change = '';
						_this.$message({
							 message: '操作成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					
				}).catch(err=>{
					loading.close();
				});
			},
		},
	}
</script>
