<template>
  <div id="app">
		<el-container style="height: 100vh;">
				<el-aside width="200px" style="background-color:#545c64">
					<div class="welcome">时事头条</div>
					<el-menu  style="width: 200px;"  background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" :router="true" :unique-opened="true" :default-active="openIndex" @select="onSelect">
						<el-submenu v-for="(item,index) in menu_list" :index="item.url" :key="index" v-if="(item.is_super==1 && is_super == 1) || (item.is_super == 0 && is_super == 0)">
							<template slot="title"><i class="el-icon-s-help"></i>{{item.title}}</template>
							<el-menu-item v-for="(it, ind) in item['sub_menu']" :index="it.url" :key="ind">
								{{it.title}}
							</el-menu-item>
						</el-submenu>
					</el-menu>
					
				</el-aside>
			<el-container>
				<el-main style="padding: 0; background-color: #f0f2f5;">
					<div style="padding: 10px 0px; background-color: white; align-items: center;  width: 100%; display: flex; flex-direction: row; justify-content: space-between;">
						<div style="margin-left: 15px;">
							<el-tag v-for="tag in tags" :key="tag.name" effect="dark" 
								:closable="tag.close" :type="tag.path == openIndex ? 'success' : 'info'"
								style="margin-right: 10px;" 
								@click="tagClick(tag.path)" @close="tagClose(tag.path)">
									{{tag.name}}
							</el-tag>
						</div>
						<div style="margin-right: 30px;">
							<span v-if="is_super==0" style="font-size: 14px; color:red; margin-right: 30px; padding-right:30px; border-right: solid 2px #aaaaaa;">剩余天数：{{hour_balance}}天</span>
							<el-dropdown style="cursor: pointer;" trigger="click">
								
								<span>{{nickname}} &nbsp;&nbsp;&nbsp;&nbsp;{{username}}<i class="el-icon-arrow-down" style="margin-left: 10px"></i></span>
								
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item><a  @click="changeDialog=true">修改密码</a></el-dropdown-item>
									<el-dropdown-item><a  @click="logout()">退出登录</a></el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
					<keep-alive exclude="createOrder">
						<router-view style="margin-top: 10px;"></router-view>
					</keep-alive>
				</el-main>
			</el-container>
		</el-container>
		
		<el-dialog title="修改密码" :visible.sync="changeDialog" width="400px" @close="passwordClose">
			<div>
				<div style="display: flex; flex-direction: row; height: 50px; align-items: center;">
					<div style="width: 80px; text-align: right; padding-right: 10px;">原始密码：</div>
					<el-input style="width: 250px;" placeholder="原始密码" show-password v-model="old_password"></el-input>
				</div>
				<div style="display: flex; flex-direction: row; height: 50px; align-items: center;">
					<div style="width: 80px; text-align: right; padding-right: 10px;">新设密码：</div>
					<el-input style="width: 250px;" placeholder="新设密码" show-password v-model="new_password"></el-input>
				</div>
				<div style="display: flex; flex-direction: row; height: 50px; align-items: center;">
					<div style="width: 80px; text-align: right; padding-right: 10px;">确认密码：</div>
					<el-input style="width: 250px;" placeholder="确认密码" show-password v-model="new_password1" ></el-input>
				</div>
				<div style="width: 100%; margin-top: 20px;">
					<el-button style="width: 100%;" type="primary" @click="changePassApi">确认修改</el-button>
				</div>
			</div>
		</el-dialog>
  </div>
</template>

<script>
import { watch } from 'vue';
  export default {
    name: 'MainFrame',
    data(){
      return {
		  hour_balance:0,
		  old_password:"",
		  new_password:"",
		  new_password1:"",
		  changeDialog:false,
		  nickname:"",
		  username:'',
		  excludeComponents: [],
		  is_super:0,
		  viewKey:1,
		  tags: [
			{ name: '数据看板', path:'/Dashboard', type: '', close:false }
		  ],
		  openIndex:'',
		  menu_list:[
			  {
				  title:'数据中心',
				  url:'/',
				  sub_menu:[
					  {
						  title:'数据看板',
						  url:'/Dashboard'
					  }
				  ]
			  },
			  {
				  is_super:0,
				  title:'会员管理',
				  url:'/User1',
				  sub_menu:[
					  {
						  title:'会员列表',
						  url:'/AgentUserList'
					  }
				  ]
			  },{
				is_super:0,
				title:'订单管理',
				url:'/Order1',
				sub_menu:[
					  {
						  title:'订单列表',
						  url:'/AgentOrderList'
					  }
				]
			  },
			  {
				  is_super:0,
				  title:'财务管理',
				  url:'/Recharge1',
				  sub_menu:[
					  {
						  title:'账变记录',
						  url:'/AgentPayRecord'
					  }
				  ]
			  },
			  {
				  is_super:1,
				  title:'用户管理',
				  url:'/User',
				  sub_menu:[
					  {
						  title:'会员列表',
						  url:'/UserList'
					  },{
						  title:'代理列表',
						  url:'/AgentList'
					  }
				  ]
			  },
			  {
				  is_super:1,
				  title:'订单管理',
				  url:'/Order',
				  sub_menu:[
					  {
						  title:'订单列表',
						  url:'/OrderList'
					  }
				  ]
			  },
			  {
				  is_super:1,
				  title:'财务管理',
				  url:'/Recharge',
				  sub_menu:[
					  {
						  title:'充值记录',
						  url:'/PayinList'
					  }
				  ]
			  },
			  {
				  is_super:1,
				  title:'系统设置',
				  url:'/Manager',
				  sub_menu:[
					  {
						title:'免责声明',
						url:'/Statement'
					  },{
						title:'系统公告',
						url:'/NoticeList'
					  },{
						title:'广告配置',
						url:'/AdList'
					  },{
						title:'系统弹窗',
						url:'/PushList'
					  }
				  ]
			  },
			  {
				  is_super:1,
				  title:'意见与反馈',
				  url:'/Suggest',
				  sub_menu:[
					  {
						title:'意见与反馈',
						url:'/Suggest'
					  }
				  ]
			  }
		  ]
      }
    },
	created() {
		this.is_super = this.$store.state.is_super;
		this.nickname = this.$store.state.nickname;
		this.username = this.$store.state.username;
		this.hour_balance = this.$store.state.hour_balance;
		this.$router.beforeEach((to, from, next) => {
			this.openIndex = to.path;
			next();
		});
	},
	mounted(){
		// 刷新页面，重置已点击菜单
		let _i = localStorage.getItem("TagsLine");
		if(_i) this.tags = JSON.parse(_i);
		this.openIndex = this.$route.path;
		if(this.openIndex == "/")
		{
			this.$router.push("/Dashboard");
		}
		this.getHourBalance();
	},
	methods:{
		getHourBalance(){
			let that = this;
			that.$api.post("/Admin/getHourBalance", {}).then(res=>{
				if(res.code == 1)
				{
					that.hour_balance = res.data.hour_balance;
				}
			}).catch(err=>{
				loading.close()
			});
		},
		changePassApi(){
			let that = this;	
			if(this.old_password.length.length < 6 || this.new_password.length <6 || this.new_password1.length < 6)
			{
				return that.$message({
					 message: '请输入完整的密码',
					 type: 'warning',
				});
			}
			if(this.new_password != this.new_password1)
			{
				return that.$message({
					 message: '新前后密码不一致',
					 type: 'warning',
				});
			}
			const loading = this.$loading({
					lock: true,
					text: '修改密码中',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
			
			that.$api.post("/Admin/changePassword", {old_password:that.old_password, new_password:that.new_password}).then(res=>{
				loading.close()
				if(res.code == 1)
				{
					return that.$message({
						 message: res.msg,
						 type: 'success',
					});
				}else{
					return that.$message({
						 message: res.msg,
						 type: 'warning',
					});
				}
			}).catch(err=>{
				loading.close()
			});
		},
		passwordClose(){
			this.old_password = "";
			this.new_password = "";
			this.new_password1= "";
		},
		refresh(){

		},
		logout(){
			localStorage.setItem("TagsLine", "");
			this.$store.commit("Logout");
			const loading = this.$loading({
				lock: true, 
				text: '退出登录中',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			setTimeout(() => {
				loading.close();
				this.$router.push("/Login");
			}, 1000);
					
					
		},
		tagClick(e){
			this.$router.push(e);
		},
		tagClose(e){
			let new_tags = [];
			let index = -1;
			this.tags.forEach((k,v)=>{
				if(k.path != e)
				{
					new_tags.push(k);
				}else{
					index = v;
				}
			});
			if( index != -1) index -=1;
			if( index < 0 ) index = 0;
			this.tags = new_tags;
			this.$router.push(this.tags.at(index).path);
			this.saveTags();
		},
		onSelect(path)
		{
			let item = null;
			this.menu_list.forEach((k,v)=>{
				k.sub_menu.forEach( (kk, vv)=>{
					if(path == kk.url)
					{
						item = kk;
					}
				})
			})
			let flag = false;
			this.tags.forEach((k, v)=>{
				if(k.path == item.url)
				{
					flag = true;
				}
			})
			if(!flag)
			{
				this.tags.push({name:item.title, path:item.url, close:true});
			}
			this.saveTags();
		},
		saveTags(){
			localStorage.setItem("TagsLine", JSON.stringify(this.tags));
		}
	}
  }
</script>

<style scoped>
	.header_box {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background-color: white!important;
		border-bottom: solid 1px #dcdcdc;
		font-size: 16px;
	}
	
	.welcome {
		height: 59px; line-height: 60px; text-align: center; color: white;
		border-bottom: solid 1px #666666;
	}
	.el-menu-item {
		background-color: #333  !important;
	}
	
	.el-aside {
	    color: #333;
	}
	
	.el-menu-item:hover{
	    outline: 0 !important;
	    color: #409EFF !important;
	}
	.el-menu-item.is-active {
	    color: #fff !important;
	    background: #409EFF !important;
	}
	
	.el-submenu__title:focus, .el-submenu__title:hover{
	    outline: 0 !important;
	    color: #409EFF !important;
	    background: none !important;
	}
	
	
	.el-aside::-webkit-scrollbar {
	      display: none;
	   }
	
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  
  .el-aside {
    color: #333;
  }
</style>
