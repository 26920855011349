import axios from 'axios'
import router from '../router'
import {Message} from 'element-ui'

// 创建axios实例
const instance = axios.create({
	baseURL: 'https://api.hhsj798.xyz/Admin', // 'https://api.hhsj798.xyz/Admin',
	timeout: 20000 // 设置请求超时时间
})

const MAX_RETRIES = 3; // 最大重试次数

// 请求拦截器
instance.interceptors.request.use(
	config => {
		config.headers.Authorization = localStorage.getItem('access_token');
		config.retryCount = config.retryCount || 0; // 初始化重试计数
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

// 响应拦截器
instance.interceptors.response.use(
	response => {
		if(response.data.code === 401)
		{
			Message({
				message: '登录超时，请重新登录',
				type: 'error'
			});
			setTimeout(()=>{
					  router.push("/Login");
			}, 1000);
			return;
		}
		return response.data
	},
	error => {
		// 如果满足重试条件，则进行重试
		if (config && config.retryCount < MAX_RETRIES) {
			config.retryCount += 1;
			return instance(config); // 重新发送请求
		}
		
		Message({
			message: error.response?.data?.msg || '请求失败，请重试',
			type: 'error'
		});
		return Promise.reject(error)
	}
)

export default instance