import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)


const store = new Vuex.Store({

    state:{
        login_status: false,
		access_token: "",
		nickname:"-",
		username:'-',
		is_super:0,
		permission_list:[]
    },
    mutations:{
		Logout(state)
		{
			state.login_status = false;
			state.access_token = "";
			state.username = "-";
			state.nickname = "-";
			state.is_super = 0;
			state.hour_balance = 0;
			state.permission_list = [];
			localStorage.setItem('storeData', JSON.stringify(state));
			console.log("Logout");
		},
		loginSuccess(state, payload)
		{
			console.log("loginSuccess", payload);
			state.login_status = true;
			state.access_token = payload.access_token;
			state.nickname 	   = payload.userinfo.nickname;
			state.username 	   = payload.userinfo.username;
			state.permission_list = payload.permission_list;
			state.hour_balance = payload.userinfo.hour_balance;
			state.is_super = payload.userinfo.is_super;
			localStorage.setItem("access_token", state.access_token);
			localStorage.setItem('storeData', JSON.stringify(state));
		},
		// 持久化数据，恢复到内存中
		recoverState(state)
		{
			let data = localStorage.getItem('storeData');
			if(data)
			{
				let data_json = JSON.parse(data);
				if(data_json)
				{
					state.login_status 	= data_json.login_status;
					state.access_token 	= data_json.access_token;
					state.nickname 		= data_json.nickname;
					state.username 		= data_json.username;
					state.hour_balance  = data_json.hour_balance;
					state.permission_list = data_json.permission_list;
					state.is_super = data_json.is_super;
					localStorage.setItem("access_token", state.access_token);
				}
			}
		}
    },
    actions:{
		initData(){
			
		}
    }


})

export default store
